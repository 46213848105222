/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('side-pop-up'), navigation = document.querySelector('.side-pop-up'), navigationContainer = document.querySelector('.side-pop-up');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Close dropdown on click
var dropdownCheckboxes = [];
document.querySelectorAll('dl.languages, dl.dropdown').forEach(el => {
	var label = el.querySelector('label');
	var checkbox = document.getElementById(label.getAttribute('for'));
	var filterInputs = el.querySelectorAll('.filter__input');

	el.setAttribute('aria-expanded', checkbox.checked);
	checkbox.addEventListener('change', e => {
		el.setAttribute('aria-expanded', checkbox.checked);

		setTimeout(function () {
			if (label.getAttribute('data-placeholder-text')) {
				var hasValue = false;

				if (filterInputs.length) {
					filterInputs.forEach(filterInput => {
						if (filterInput.checked) {
							hasValue = true;
						}
					});
				}

				if (!hasValue) {
					label.innerText = label.getAttribute('data-placeholder-text');
				}
			}
		}, 10);
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			checkbox.checked = !checkbox.checked;
			e.preventDefault();
		}
	});

	// Close 
	el.querySelector('dd').addEventListener('click', e => {
		checkbox.checked = false;
		var evt = new Event("change");
		checkbox.dispatchEvent(evt);
	});

	// Change label text
	if (label.getAttribute('data-placeholder-text')) {
		var items = el.querySelectorAll('.dropdown__item-label');
		if (items.length > 0) {
			items.forEach(item => {
				item.addEventListener('click', e => {
					label.innerText = item.innerText;
				});
			});
		}
	}

	dropdownCheckboxes.push(checkbox);
});

document.addEventListener('click', e => {

	if (e.target.closest('.dropdown') === null && e.target.closest('.dropdown__checkbox') === null && e.target.closest('.dropdown__checkbox') === null) {

		dropdownCheckboxes.forEach(el => {
			el.checked = false;
			const e = new Event("change");
			el.dispatchEvent(e);
		});
	}
});

// Slider block
var sliders = document.querySelectorAll('.content-block.is-slider-block, .content-block.is-home-projects-block');
if (sliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {

			var pagination = {
				el: el.querySelector('.swiper-pagination'),
				type: 'bullets',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + ".</span>";
				}
			};

			if (el.classList.contains('is-home-projects-block')) {
				pagination = {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				};
			}

			new Swiper(el.querySelector('.swiper-container'), {
				effect: "fade",
				fadeEffect: {
					crossFade: true
				},
				watchOverflow: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: true
				},
				speed: 1000,
				slidesPerView: 1,
				keyboard: {
					enabled: true
				},
				pagination: pagination
			});
		});
	})();
}

// Slider block
var textSliders = document.querySelectorAll('.content-block.is-text-slider');
if (textSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		textSliders.forEach(el => {
			var container = el.querySelector('.content-block__slider-container'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div');

			swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
			swiperButtonNext.setAttribute('class', 'swiper-button-next');
			container.appendChild(swiperButtonNext);
			container.appendChild(swiperButtonPrev);

			var textSlider = new Swiper(el.querySelector('.swiper-container.is-text-slider'), {
				watchOverflow: true,
				loop: true,

				autoplay: {
					delay: 2000,
					disableOnInteraction: true
				},
				speed: 1000,
				slidesPerView: 1,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonNext,
					prevEl: swiperButtonPrev
				}
			});

			var buttonSlider = new Swiper(el.querySelector('.swiper-container.is-button-slider'), {
				effect: "fade",
				fadeEffect: {
					crossFade: true
				},
				loop: true,
				slidesPerView: 1
			});

			textSlider.controller.control = buttonSlider;
			buttonSlider.controller.control = textSlider; 
		});
	})();
}

// Employee slider
var employeeSlider = document.querySelectorAll('.content-block.is-solutions-slider.has-yellow-background');
if (employeeSlider.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		employeeSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = el.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			el.appendChild(swiperButtonPrev);
			el.appendChild(swiperButtonNext);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			var slidesPerView = 1,
				spaceBetween = 48,
				loop = false,
				autoplay = true,
				breakpoints = { 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3 }, 1440: { slidesPerView: 4 } };

			if (el.classList.contains('is-solutions-slider')) {
				breakpoints = { 768: { slidesPerView: 2 } };
				autoplay = false;

			}

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: slidesPerView,
				breakpoints: breakpoints,
				spaceBetween: spaceBetween,
				speed: 1000,
				loop: loop,
				autoplay: autoplay,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

//Filter
const filter = document.querySelector('.filter-block form');
const overview = document.querySelector('.projects .columns');
const projectsContainer = document.querySelector('.projects .container');
if (filter && overview) {

	// Filter event
	var filterEvent = function (e) {
		// Get the checked inputs from the form
		const data = new URLSearchParams(new FormData(filter));

		// Get new overview
		fetch(window.location.pathname, {
			method: "post",
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		})
			.then(response => response.text())
			.then(data => {

				// Empty overview
				overview.innerHTML = '';

				// Create DOM
				var parser = new DOMParser();
				var doc = parser.parseFromString(data, 'text/html');

				// Add items with animation
				doc.querySelectorAll('.column').forEach(function (child, i) {
					child.classList.add("is-hidden");
					overview.appendChild(child);
					setTimeout(function () { child.classList.remove('is-hidden'); }, 40 * i);
					i++;
				});

				// Remove old navigation
				var oldprojectsContainer = projectsContainer.querySelector('.overview-navigation');
				if (oldprojectsContainer) {
					oldprojectsContainer.remove();
				}

				// Add new navigation
				var paginationContainer = doc.querySelector('.overview-navigation');
				if (paginationContainer) {
					projectsContainer.appendChild(paginationContainer);
				}
			});

		// Update the url
		if (history.pushState) {

			if (data.toString() === "") {
				window.history.replaceState({}, '', `${location.pathname}`);
			}
			else {
				window.history.replaceState({}, '', `${location.pathname}?${data}`);
			}
		}

		e.preventDefault();
	};

	// Get all the inputs
	var inputs = filter.querySelectorAll('input.filter__input');
	inputs.forEach(el => {

		// Add on change event to inputs
		el.addEventListener("change", function (e) {
			filterEvent(e);
		});
	});

	// Reset button
	var resetFilter = filter.querySelector('.filter__reset');
	var solutionLabel = filter.querySelector('.solutions.dropdown dt label');
	if (resetFilter) {
		resetFilter.addEventListener('click', function (e) {
			filter.reset();

			inputs.forEach(el => {
				el.checked = false;
			});

			filterEvent(e);
			solutionLabel.innerText = solutionLabel.getAttribute('data-placeholder-text');
			e.preventDefault();
		});
	}
}

// Jump to submitted form
document.querySelectorAll('.content-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}
